import axios from "axios";
import { CONFIG } from "@/constants/config.js";
import router from "@/router";

const priceGroupsBackend = CONFIG.BACKEND_URL + "priceGroup";

const state = {
  priceGroupSummary: {},
  priceGroups: [],
  customersProductGroups: [],
  priceGroupItem: {},
  priceGroup: [],
  loading: false,
  error: "",
  currentPage: 1,
  numPages: 1,
  totalRows: 0,
};

const config = () => ({
  headers: {
    Token: localStorage.getItem("accessToken"),
  },
});

const getters = {
  priceGroupSummary: (state) => state.priceGroupSummary,
  allPriceGroups: (state) => state.priceGroups,
  customersProductGroups: (state) => state.customersProductGroups,
  priceGroupItem: (state) => state.priceGroupItem,
  priceGroup: (state) => state.priceGroup,
  priceGroupsLoading: (state) => state.loading,
  priceGroupsError: (state) => state.error,
  priceGroupsCurrentPage: (state) => state.currentPage,
  priceGroupsNumPages: (state) => state.numPages,
  priceGroupsTotalRows: (state) => state.totalRows,
  getPriceGroupByID: (state) => (id) => {
    return state.priceGroups.find((priceGroup) => priceGroup.id === id);
  },
};

const actions = {
  async getPriceGroup({ commit /*, dispatch */ }, id) {
    commit("setLoading", true);
    await axios
      .post(`${priceGroupsBackend}/summary/${id}/`, null, config())
      .then(async (response) => {
        commit("setLoading", false);
        await commit("setPriceGroupSummary", response.data);
        commit("setError", "");
      })
      .catch(async (error) => {
        await router.push("/priceGroups");
        if (error.response) {
          await commit("resetPriceGroupSummary");
          alert(error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
        }
        console.log(error);
      });
  },
  async getPriceGroups({ commit /*, dispatch */ }, filter = null) {
    commit("setLoading", true);
    await axios
      .post(`${priceGroupsBackend}/all/`, filter, config())
      .then(async (response) => {
        commit("setLoading", false);
        await commit("setPriceGroups", response.data);
        commit("setError", "");
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("resetPriceGroup");
          commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  async getCustomersProductGroups(
    { commit /*, dispatch */ },
    { id, filter = null }
  ) {
    commit("setLoading", true);
    await axios
      .post(`${priceGroupsBackend}/all/productGroups/${id}/`, filter, config())
      .then(async (response) => {
        commit("setLoading", false);
        await commit("setCustomersProductGroups", response.data);
        commit("setError", "");
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("resetPriceGroup");
          commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  async updatePriceGroupProductGroups({ commit /*, dispatch */ }, id) {
    commit("setLoading", true);
    await axios
      .post(
        `${priceGroupsBackend}/all/productGroups/update/${id}/`,
        null,
        config()
      )
      .then(async (response) => {
        commit("setLoading", false);
        console.log(response.data);
        commit("setError", "");
      });
  },
  async getPriceGroupItem({ commit /*, dispatch */ }, id) {
    commit("setLoading", true);
    await axios
      .post(`${priceGroupsBackend}/item/${id}/`, null, config())
      .then(async (response) => {
        commit("setLoading", false);
        await commit("setPriceGroupItem", response.data);
        commit("setError", "");
      })
      .catch(async (error) => {
        await router.push("/priceGroups");
        if (error.response.data.message) {
          await commit("resetPriceGroupSummary");
          await commit("resetPriceGroup");
          alert(error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
        }
        console.log(error);
      });
  },
  async getPriceGroupItems({ commit /*, dispatch */ }, { id, filter = null }) {
    commit("setLoading", true);
    console.log(filter);
    await axios
      .post(`${priceGroupsBackend}/${id}/`, filter, config())
      .then(async (response) => {
        commit("setLoading", false);
        await commit("setPriceGroup", response.data);
        commit("setError", "");
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("resetPriceGroup");
          commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
        commit("setLoading", false);
      });
  },
  async addPriceGroup({ commit /*, dispatch */ }, priceGroup) {
    commit("setLoading", true);
    await axios
      .post(`${priceGroupsBackend}/new/`, priceGroup, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "success", message: "PriceGroup Edited Successfully Created" },
        //   { root: true }
        // );
      })
      .catch((error) => {
        if (error.response.data.message) {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: error.message },
          //   { root: true }
          // );
        }
        commit("setLoading", false);
        commit("setError", error.data.message);
      });
  },
  async addPriceGroupItem({ commit /*, dispatch */ }, priceGroupItem) {
    commit("setLoading", true);
    await axios
      .post(`${priceGroupsBackend}/insert/`, priceGroupItem, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "success", message: "PriceGroup Edited Successfully Created" },
        //   { root: true }
        // );
      })
      .catch((error) => {
        if (error.response.data.message) {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: error.message },
          //   { root: true }
          // );
        }
        commit("setLoading", false);
        commit("setError", error.data.message);
      });
  },
  // eslint-disable-next-line no-unused-vars
  async editPriceGroup({ commit, dispatch }, priceGroup) {
    commit("setLoading", true);
    await axios
      .patch(`${priceGroupsBackend}/update/`, priceGroup, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "success", message: "PriceGroup Edited Successfully Created" },
        //   { root: true }
        // );
      })
      .catch((error) => {
        if (error.response.data.message) {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: error.message },
          //   { root: true }
          // );
        }
        commit("setLoading", false);
        commit("setError", error.data.message);
      });
  },
  // eslint-disable-next-line no-unused-vars
  async editPriceGroupItem({ commit, dispatch }, priceGroup) {
    commit("setLoading", true);
    await axios
      .patch(`${priceGroupsBackend}/update/item/`, priceGroup, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "success", message: "PriceGroup Edited Successfully Created" },
        //   { root: true }
        // );
      })
      .catch((error) => {
        if (error.response.data.message) {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: error.message },
          //   { root: true }
          // );
        }
        commit("setLoading", false);
        commit("setError", error.data.message);
      });
  },
  // eslint-disable-next-line no-unused-vars
  async addCustomersProductGroupItems({ commit, dispatch }, productGroup) {
    commit("setLoading", true);
    await axios
      .post(
        `${priceGroupsBackend}/insert/productGroup/`,
        productGroup,
        config()
      )
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "success", message: "PriceGroup Edited Successfully Created" },
        //   { root: true }
        // );
      })
      .catch((error) => {
        if (error.response.data.message) {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: error.message },
          //   { root: true }
          // );
        }
        commit("setLoading", false);
        commit("setError", error.data.message);
      });
  },
  // eslint-disable-next-line no-unused-vars
  async deletePriceGroup({ commit, dispatch }, id) {
    commit("setLoading", true);
    await axios
      .delete(`${priceGroupsBackend}/delete/${id}/`, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "success", message: "PriceGroup Edited Successfully Created" },
        //   { root: true }
        // );
      })
      .catch((error) => {
        if (error.response.data.message) {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: error.message },
          //   { root: true }
          // );
        }
        commit("setLoading", false);
        commit("setError", error.data.message);
      });
  },
  // eslint-disable-next-line no-unused-vars
  async deletePriceGroupItem({ commit, dispatch }, id) {
    commit("setLoading", true);
    await axios
      .delete(`${priceGroupsBackend}/delete/item/${id}/`, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "success", message: "PriceGroup Edited Successfully Created" },
        //   { root: true }
        // );
      })
      .catch((error) => {
        if (error.response.data.message) {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: error.message },
          //   { root: true }
          // );
        }
        commit("setLoading", false);
        commit("setError", error.data.message);
      });
  },
  // eslint-disable-next-line no-unused-vars
  async deleteSelectedPriceGroupItems({ commit, dispatch }, priceGroupItems) {
    commit("setLoading", true);
    await axios
      .post(`${priceGroupsBackend}/delete/item/`, priceGroupItems, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "success", message: "Product Edited Successfully Created" },
        //   { root: true }
        // );
      })
      .catch((error) => {
        if (error.response.data.message) {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: error.message },
          //   { root: true }
          // );
        }
        commit("setLoading", false);
        commit("setError", error.data.message);
        throw error;
      });
  },
};

const mutations = {
  resetPriceGroupSummary(state) {
    state.priceGroupSummary = {};
  },
  resetPriceGroup(state) {
    state.priceGroups = [];
    state.customersProductGroups = [];
    state.priceGroupItem = {};
    state.priceGroup = [];
    state.loading = false;
    state.error = "";
    state.currentPage = 1;
    state.numPages = 1;
    state.totalRows = 0;
  },
  setPriceGroupSummary(state, response) {
    state.priceGroupSummary = response;
  },
  setPriceGroups(state, response) {
    state.priceGroups = response.priceGroups;
    state.currentPage = response.currentPage;
    state.numPages = response.numPages;
    state.totalRows = response.totalRows;
  },
  setCustomersProductGroups(state, response) {
    state.customersProductGroups = response.productGroups;
    state.currentPage = response.currentPage;
    state.numPages = response.numPages;
    state.totalRows = response.totalRows;
  },
  setPriceGroupItem(state, response) {
    state.priceGroupItem = response;
  },
  setPriceGroup(state, response) {
    state.priceGroup = response.priceGroupItems;
    state.currentPage = response.currentPage;
    state.numPages = response.numPages;
    state.totalRows = response.totalRows;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, error) {
    state.error = error;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
